export default class BackofficeIncidentCriticitiesForm {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      $(".entities-select").select2({
        placeholder: "Sélectionner un ou plusieurs opérateur(s)",
        width: '100%',
        allowClear: true,
        closeOnSelect: false
      });

      if (!$('#forAllEntitiesSwitch').is(':checked')) {
        $('.entities-select-container').show();
        $('.entities-select').attr('required', true);
      }
    
      $('#forAllEntitiesSwitch').on('change', function() {
        if ($(this).is(':checked')) {
          $('.entities-select-container').hide();
          $('.entities-select').val(null).trigger('change');
          $('.entities-select').removeAttr('required');
        } else {
          $('.entities-select-container').show();
          $('.entities-select').attr('required', true);
        }
      });
    }
  
    onDataChanged(data) {
  
    }
  
    onDestroy() {

    }
}