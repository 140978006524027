export default class ActionAssignation {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".action-assignation-btn").on('click', this.showActionAssignationModal);
    $("#action-assignation-modal-save").on('click', this.saveActionAssignation);
  }

  showActionAssignationModal() {
    const url = $(this).data('url');

    $.ajax({
      url: url,
      method: 'GET',
      success: function(data) {
        $("#action-assignation-modal-body").html(data);
        $("#action-assignation-modal").modal('show');
      }
    });
  }

  saveActionAssignation() {
    let form = $("#action-assignation-form");
    

    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize(),
      success: function(data) {
        $("#action-assignation-modal").modal('hide');
        var action_id = data.action_id;
        console.log(data);

        $("#" + action_id + "-assigned-to").html(data.assigned_to)
        application.successNotification(I18n.t('backoffice.actions.assignation_success'));
      }
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $(".action-assignation-btn").off('click', this.showActionAssignationModal);
    $("#action-assignation-modal-save").off('click', this.saveActionAssignation);
  }
}