export default class BackofficeOrdersPdfIndex {

  constructor() {
      this.bindEvents();
  }


  bindEvents() {
    $('#orders-datatable').DataTable({
      "language": {
        url: '/assets/data-tables/locales/' + I18n.locale + '.json',
        previous: "<i class='mdi mdi-chevron-left'>",
        next: "<i class='mdi mdi-chevron-right>",
      },
      "order": [[4, 'desc']]
    });
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $('#orders-datatable').DataTable().destroy();
  }
}