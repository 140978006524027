import TaskAssignation from '../utils/TaskAssignation';

export default class BackofficeTasksIndex {
  constructor() {
    this.bindEvents();
    this.taskAssignation = new TaskAssignation();
  }

  bindEvents() {
    $(".tasks-datatable").dataTable({
      searching: false,
      order: [[2, 'asc']],
      language: {
        url: '/data-tables/locales/' + I18n.locale + '.json'
      }
    })

    $(".action-tasks-datatable").dataTable({
      searching: false,
      order: [[4, 'asc']],
      language: {
        url: '/data-tables/locales/' + I18n.locale + '.json'
      }
    })
  }

  
  onDataChanged(data) {
  }

  onDestroy() {
    this.taskAssignation.onDestroy();
    $(".tasks-datatable").DataTable().destroy();
    $(".action-tasks-datatable").DataTable().destroy();
  }
}