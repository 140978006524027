export default class BackofficeProductsForm {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $('.service_id').on('change', this.toggleZoneSelect.bind(this));
    this.toggleZoneSelect();
  }

  toggleZoneSelect() {    
    const selectedOption = $('.service_id option:selected');
    const apiType = selectedOption.data('api-type');

    if (apiType === 'api_type_ftto_tree') {
      $('#zone-select').show();
    } else {
      $('#zone-select').hide();
    }
  }

  onDataChanged(data) {

  }

  onDestroy() {

  }
}