export default class BackofficeNotificationsIndex {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
  }

  onDataChanged(data) {
  }

  onDestroy() {
   
  }

  initDatatable() {

  }
}