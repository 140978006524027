import BackofficeIndexBase from './BackofficeIndexBase';

export default class BackofficeOrdersIndex extends BackofficeIndexBase {
  constructor() {
    super("#orders-datatable");

    this.bindEvents();
  }

  bindEvents() {
    $('#saveOrderStep').off('click').on('click', function(event) {
      event.preventDefault(); 

      const url = $(this).data('url');
     
      $.ajax({
        url: url,
        type: 'PUT',
        contentType: 'application/json',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {
          application.successNotification("Filtre sauvegardé");
          
          // add class disabled to button
          $('#saveOrderStep').addClass('disabled');
        },
        error: function(error) {
          application.errorNotification("Erreur lors de la sauvegarde du filtre");
        }
      });
    });
  }

  onDataChanged(data) {
    // Custom logic for orders
  }
}