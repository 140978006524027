export default class BackofficeOperationDataTable {
  constructor(tableId) {
    this.tableId = tableId;
    this.initDataTable();
    this.bindEvents();
  }

  initDataTable() {
    const self = this;
  
    var data_order = $(this.tableId).data('order-by');
  
    const dataTableOptions = {
      "dom": '<"datatable-controls d-flex justify-content-between"Blf>rtip',
      "responsive": true,
      "searching": true,
      "columnDefs": [{
        "targets": -1,
        "orderable": false
      }],
      "language": {
        url: '/assets/data-tables/locales/' + I18n.locale + '.json',
        previous: "<i class='mdi mdi-chevron-left'>",
        next: "<i class='mdi mdi-chevron-right>",
      },
      "buttons": [
        {
          extend: 'csvHtml5',
          text: 'Export CSV',
          className: 'd-none',
          exportOptions: {
            columns: ':not(:last-child)'
          }
        },
        {
          extend: 'excelHtml5',
          text: 'Export Excel',
          className: 'd-none',
          exportOptions: {
            columns: ':not(:last-child)'
          }
        }
      ],
      initComplete: function() {
        $(self.tableId + "_filter").addClass('d-none');
        
        $(self.tableId + '_length').appendTo('.datatable-controls');
        $("#datatable-controls").appendTo('.datatable-controls');
  
        $(".dt-buttons").addClass("d-none");
      }
    };
  
    if (data_order !== null && data_order !== "") {
      dataTableOptions.order = [[data_order, "desc"]];
    } else {
      dataTableOptions.order = [];
    }
  
    this.table = $(this.tableId).DataTable(dataTableOptions);
  }

  bindEvents() {
    const self = this;

    $(".select-multiple").select2({
      multiple: true,
      closeOnSelect: false,
      width: '100%'
    });

    $("#filter-form-submit").on('click', function() {
      $("#filter-form").trigger('submit');
      $(".spinner-border").removeClass('d-none');
      $(this).prop('disabled', true);
    });

    $("#customSearch").on('keyup', function() {
      self.table.search($(this).val()).draw();
    });

    // Export buttons
    $("#export-csv").on('click', function() {
      self.table.button('.buttons-csv').trigger();
    });

    $("#export-excel").on('click', function() {
      self.table.button('.buttons-excel').trigger();
    });
  }

  onDestroy() {
    this.table.destroy();
  }
}