export default class BackofficeServicesForm {

  constructor() {
    this.bindEvents();
    this.checkInitialModelUniversalId();
  }

  bindEvents() {
    $('.select2').select2({
      placeholder: "Choisissez une ou plusieurs entreprises",
      allowClear: true,
      closeOnSelect: false
    });

    $('#use_model_universal_id').on('change', function() {
      if ($(this).is(':checked')) {
        $('#model_universal_id_wrapper').removeClass('d-none');
      } else {
        $('#model_universal_id_wrapper').addClass('d-none');
        $("input[name='service[model_universal_id]']").val('');
      }
    });
  }

  checkInitialModelUniversalId() {
    if ($('input[name="service[model_universal_id]"]').val()) {
      $('#use_model_universal_id').prop('checked', true);
      $('#model_universal_id_wrapper').removeClass('d-none');
    } else {
      $('#use_model_universal_id').prop('checked', false);
      $('#model_universal_id_wrapper').addClass('d-none');
    }
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $(".select2").select2('destroy');
  }
}