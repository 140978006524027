export default class BackofficeIncidentCategoriesForm {

  constructor() {
      this.bindEvents();
  }

  bindEvents() {
    this.initializeSelect2('.entities-select');
    this.initializeSelect2('.excluded-entities-select');

    if (!$('#forAllEntitiesSwitch').is(':checked')) {
      $('.entities-select-container').show();
      $('.entities-select').attr('required', true);
    }
  
    $('#forAllEntitiesSwitch').on('change', function() {
      if ($(this).is(':checked')) {
        $('.entities-select-container').hide();
        $('.entities-select').val(null).trigger('change');
        $('.entities-select').removeAttr('required');
      } else {
        $('.entities-select-container').show();
        $('.entities-select').attr('required', true);
      }
    });

    $("#add_customer_id").on("click", function () {
      $("#customer_ids_container").append(`
        <div class="input-group mb-2">
          <input type="text" name="incident_category[services_association_related_customer_ids][]" class="form-control" placeholder="Entrer un ID">
          <div class="input-group-append">
            <button type="button" class="btn btn-danger remove-field">✖</button>
          </div>
        </div>
      `);
    });
  
    // Ajouter un Service
    $("#add_service").on("click", function () {
      $("#services_container").append(`
        <div class="input-group mb-2">
          <input type="text" name="incident_category[services_associated][]" class="form-control" placeholder="Entrer un service">
          <div class="input-group-append">
            <button type="button" class="btn btn-danger remove-field">✖</button>
          </div>
        </div>
      `);
    });
  
    // Supprimer un champ
    $(document).on("click", ".remove-field", function () {
      $(this).closest(".input-group").remove();
    });
  }

  initializeSelect2(selector) {
    $(selector).select2({
      placeholder: "Sélectionner un ou plusieurs opérateur(s)",
      width: '100%',
      allowClear: true,
      closeOnSelect: false
    });
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $('#forAllEntitiesSwitch').off('change');
    $('.entities-select').select2('destroy');
    $('.excluded-entities-select').select2('destroy');
  }
}