
export default class BackofficeStepsNew {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".steps-select").select2({
      placeholder: 'Choisir une ou plusieurs étapes',
      closeOnSelect: false,
      multiple: true
    });

    this.toggleStepFormWrapper();
    this.bindStepFormSwitch();
  }

  toggleStepFormWrapper() {
    if (!$("#custom-switch-step-form").prop('checked')) {
      $("#steps-select-wrapper").toggleClass('d-none');
    }
  }

  bindStepFormSwitch() {
    $("#custom-switch-step-form").on('change', () => {
      $("#steps-select-wrapper").toggleClass('d-none');
    });
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $(".steps-select").select2('destroy');
  }
}

